import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout.js"
import Bio from "../components/bio.js"
import SEO from "../components/seo.js"

export default ({ data }) => (
    <Layout>
        <SEO title="Home" />
        <Bio />
        <p>{data.allMarkdownRemark.totalCount || 0} posts</p>
        {
            data.allMarkdownRemark.edges.map(({ node }) => (
                <Link to={node.fields.slug} key={node.id}>
                    <div key={node.id} className="post-stub blue-box">
                        <h2>
                                {node.frontmatter.title}
                        </h2>
                        <p>{node.frontmatter.description}</p>
                        <div className='tags'>
                            
                        </div>
                    </div>
                </Link>
            ))
        }
    </Layout>
)

export const query = graphql`
    query { 
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                        description
                    }
                    excerpt
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
