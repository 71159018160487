import React from 'react'
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const style = css`
    padding: 1em 2em;
    display: flex;
    align-items: center;
    img {
        height: 10em;
        min-width: 10em;
        margin-right: 2em;
        border-radius: 50%;
    }
    @media only screen and (max-width: 600px) {
       flex-direction: column;
       text-align: center;
       img {
            margin: 0;
       }
    }
`;
export default () => (
    <div className='bio' css={style}>
        <img src={require('../images/profile.jpg')} alt='site author'/>
        <p>
            Isabelle Wagenvoord is a high school student 
            who loves technology! Her interests include front 
            end web development, Amazon Web Services, and the 
            Raspberry Pi. 
        </p>
    </div>
);